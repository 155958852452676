import React from "react";
import { AppContext } from "../Store";

function Notification({ isModal, closeNewTaskForced }) {
  const [state, setState] = React.useContext(AppContext);

  React.useEffect(() => {
    setTimeout(() => {
      setState({
        ...state,
        notification: {
          type: null,
          error: null,
          visible: false,
        },
      });

      if (isModal) {
        closeNewTaskForced();
      }
    }, 7500);
  }, []);

  return (
    <div
      className={`h-28 w-72 ${
        state.notification.visible === true ? "visible" : "hidden"
      } absolute flex flex-row items-start justify-between transition-all duration-300 bottom-4 right-4 bg-gray-800 rounded shadow-lg p-4 text-white`}
    >
      <span
        className={`text-4xl ${
          state.notification.type === "error"
            ? "text-rose-500"
            : "text-lime-500"
        }`}
      >
        {state.notification.type === "error" ? (
          <ion-icon name="warning-outline"></ion-icon>
        ) : (
          <ion-icon name="checkmark-circle-outline"></ion-icon>
        )}
      </span>

      <span className="text-sm pl-2">{state.notification.error}</span>
    </div>
  );
}

export default React.memo(Notification);
