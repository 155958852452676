import React from "react";
import axios from "axios";
import moment from "moment";
import { Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "./Store";

// import Layout
import Header from "./layout/Header";
import Sidebar from "./layout/Sidebar";
import Footer from "./layout/Footer";

// import Views
import Auth from "./views/Auth";
import Reset from "./views/Reset";
import ResetPassword from "./views/ResetPassword";
import Home from "./views/Home";
import OpenTickets from "./views/OpenTickets";
import ScheduledTickets from "./views/ScheduledTickets";
import ActiveTickets from "./views/ActiveTickets";
import CompletedTickets from "./views/CompletedTickets";

// import Components
import NewTask from "./components/NewTask";
import Loading from "./components/Loading";

function App() {
  const { i18n } = useTranslation();
  const [state, setState] = React.useContext(AppContext);

  React.useEffect(() => {
    i18n.changeLanguage(state.language);
    setState({ ...state, language: localStorage.getItem("i18nextLng") });
  }, [i18n, state.language]);

  React.useEffect(() => {
    const isLoggedIn = localStorage.getItem("auth");
    setState({
      ...state,
      auth: Boolean(isLoggedIn),
    });
  }, []);

  React.useEffect(() => {
    if (state.auth === true) {
      let formData = new FormData();
      formData.append("id", state.user.users_id[0]);
      formData.append("email", state.user.email);
      formData.append("company", state.user.companies_full[0]);

      axios
        .post(
          process.env.NODE_ENV !== "development"
            ? `${process.env.REACT_APP_PROD_URL}/ping_quota.php`
            : `${process.env.REACT_APP_DEV_URL}/ping_quota.php`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              "Allow-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          console.log("Logged...");
        });
      getTasks(state.use);
    }
    // const interval = setInterval(() => {
    //   if (user || auth) {
    //     getTasks();
    //   }
    // }, 90000);
    // return () => {
    //   clearInterval(interval);
    // };
  }, [state.auth, state.use]);

  React.useEffect(() => {
    if (state.auth) {
      if (state.company === "all") {
        setState({ ...state, tickets: state.initTickets });
      } else {
        const initOpenTickets = state.initTickets.open;
        const initScheduledTickets = state.initTickets.scheduled;
        const initActiveTickets = state.initTickets.active;
        const initCompletedTickets = state.initTickets.completed;
        let data = [];
        let totalBilled = 0;
        let totalBilledItems = 0;
        let timeTotalCompleted = 0;
        let timeTotalToBeInvoiced = 0;
        let timeTotalInvoiced = 0;
        let timeTotalCompletedDate = 0;
        let timeTotalToBeInvoicedDate = 0;
        let timeTotalInvoicedDate = 0;
        const filteredOpenTickets = initOpenTickets.filter(
          (ticket) => ticket[17] === state.company
        );
        const filteredScheduledTickets = initScheduledTickets.filter(
          (ticket) => ticket[17] === state.company
        );
        const filteredActiveTickets = initActiveTickets.filter(
          (ticket) => ticket[17] === state.company
        );
        const filteredCompletedTickets = initCompletedTickets.filter(
          (ticket) => ticket[17] === state.company
        );
        filteredOpenTickets.forEach((ticket) => {
          totalBilled += parseFloat(ticket[20]);
          totalBilledItems += parseFloat(ticket[19]);
        });
        filteredScheduledTickets.forEach((ticket) => {
          totalBilled += parseFloat(ticket[20]);
          totalBilledItems += parseFloat(ticket[19]);
        });
        filteredActiveTickets.forEach((ticket) => {
          totalBilled += parseFloat(ticket[20]);
          totalBilledItems += parseFloat(ticket[19]);
        });
        filteredCompletedTickets.forEach((ticket) => {
          const end =
            ticket[7] !== ""
              ? moment(ticket[7], "YYYY-MM-DD H:m")
              : moment(ticket[6], "YYYY-MM-DD H:m");
          const start = moment(ticket[6], "YYYY-MM-DD H:m");
          const duration = moment.duration(end.diff(start));
          const hours = duration.hours();
          if (ticket[4] !== "Ολοκληρώθηκε") {
            totalBilled += parseFloat(ticket[20]);
            totalBilledItems += parseFloat(ticket[19]);
            if (ticket[4] === "Προς τιμολόγηση") {
              timeTotalToBeInvoiced += parseFloat(ticket[22]);
              timeTotalToBeInvoicedDate += hours;
            } else {
              timeTotalInvoiced += parseFloat(ticket[22]);
              timeTotalInvoicedDate += hours;
            }
          } else {
            timeTotalCompleted += parseFloat(ticket[22]);
            timeTotalCompletedDate += hours;
          }
        });
        data.open = filteredOpenTickets;
        data.scheduled = filteredScheduledTickets;
        data.active = filteredActiveTickets;
        data.completed = filteredCompletedTickets;
        data.totalBilled = (
          Math.round((totalBilled + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        data.totalBilledItems = (
          Math.round((totalBilledItems + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        data.timeTotalCompleted = (
          Math.round((timeTotalCompleted + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        data.timeTotalToBeInvoiced = (
          Math.round((timeTotalToBeInvoiced + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        data.timeTotalInvoiced = (
          Math.round((timeTotalInvoiced + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        data.timeTotalCompletedDate = (
          Math.round((timeTotalCompletedDate + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        data.timeTotalToBeInvoicedDate = (
          Math.round((timeTotalToBeInvoicedDate + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        data.timeTotalInvoicedDate = (
          Math.round((timeTotalInvoicedDate + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        setState({ ...state, tickets: data });
      }
    }
  }, [state.company]);

  React.useEffect(() => {
    const isLoggedIn = localStorage.getItem("auth");
    const userInfo = localStorage.getItem("userInfo");
    setState({
      ...state,
      auth: Boolean(isLoggedIn),
      user: JSON.parse(userInfo),
    });
  }, []);

  const getTasks = (use) => {
    let formData = new FormData();

    formData.append("init", "true");
    formData.append("query", state.user.query);
    formData.append("use", use);

    axios
      .post(
        process.env.NODE_ENV !== "development"
          ? `${process.env.REACT_APP_PROD_URL}/fetch_all.php`
          : `${process.env.REACT_APP_DEV_URL}/fetch_all.php`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "Allow-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        let data = {
          open: [],
          scheduled: [],
          active: [],
          completed: [],
        };

        let totalBilled = 0;
        let totalBilledItems = 0;
        let timeTotalCompleted = 0;
        let timeTotalToBeInvoiced = 0;
        let timeTotalInvoiced = 0;

        let timeTotalCompletedDate = 0;
        let timeTotalToBeInvoicedDate = 0;
        let timeTotalInvoicedDate = 0;

        if (res.data !== "empty") {
          res.data.rows.forEach((row) => {
            console.log(row);
            if (row[4] === "To be scheduled") {
              data.open.push(row);
            } else if (row[4] === "Προς έναρξη") {
              data.scheduled.push(row);
            } else if (row[4] === "Σε εξέλιξη" || row[4] === "Σε Παύση") {
              data.active.push(row);
            } else if (
              row[4] === "Ολοκληρώθηκε" ||
              row[4] === "Προς τιμολόγηση" ||
              row[4] === "Τιμολογήθηκε"
            ) {
              const end =
                row[7] !== ""
                  ? moment(row[7], "YYYY-MM-DD H:m")
                  : moment(row[6], "YYYY-MM-DD H:m");
              const start = moment(row[6], "YYYY-MM-DD H:m");

              const duration = moment.duration(end.diff(start));
              const hours = duration.hours() * 60 + duration.minutes();

              if (row[4] !== "Ολοκληρώθηκε") {
                totalBilled += parseFloat(row[20]);
                totalBilledItems += parseFloat(row[19]);

                if (row[4] === "Προς τιμολόγηση") {
                  timeTotalToBeInvoiced += parseFloat(row[22]);
                  timeTotalToBeInvoicedDate += hours;
                } else {
                  timeTotalInvoiced += parseFloat(row[22]);
                  timeTotalInvoicedDate += hours;
                }
              } else {
                timeTotalCompleted += parseFloat(row[22]);
                timeTotalCompletedDate += hours;
              }

              data.completed.push(row);
            }
          });
        }

        data.totalBilled = (
          Math.round((totalBilled + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        data.totalBilledItems = (
          Math.round((totalBilledItems + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        data.timeTotalCompleted = (
          Math.round((timeTotalCompleted + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        data.timeTotalToBeInvoiced = (
          Math.round((timeTotalToBeInvoiced + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        data.timeTotalInvoiced = (
          Math.round((timeTotalInvoiced + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        data.timeTotalCompletedDate = (
          Math.round((timeTotalCompletedDate + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        data.timeTotalToBeInvoicedDate = (
          Math.round((timeTotalToBeInvoicedDate + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        data.timeTotalInvoicedDate = (
          Math.round((timeTotalInvoicedDate + Number.EPSILON) * 100) / 100
        ).toFixed(2);
        return data;
      })
      .then((data) => {
        data.open.sort((a, b) => {
          const d1 = new Date(a[6]);
          const d2 = new Date(b[6]);

          return d2 - d1;
        });

        data.scheduled.sort((a, b) => {
          const d1 = new Date(a[6]);
          const d2 = new Date(b[6]);

          return d2 - d1;
        });

        data.active.sort((a, b) => {
          const d1 = new Date(a[6]);
          const d2 = new Date(b[6]);

          return d2 - d1;
        });

        data.completed.sort((a, b) => {
          const d1 = new Date(a[6]);
          const d2 = new Date(b[6]);

          return d2 - d1;
        });

        console.log(data.timeTotalCompletedDate);

        setState({
          ...state,
          initTickets: data,
          tickets: data,
          loading: false,
        });
      });
  };

  const toggleSidebar = () => {
    document.querySelector(".sidebar").classList.toggle("md:-translate-x-full");

    const curIsOpen = state.isOpen;

    setState({
      ...state,
      isOpen: !curIsOpen,
    });
  };

  return (
    <div className={`w-full flex md:relative min-h-screen`}>
      {state.auth ? <Sidebar toggleSidebar={toggleSidebar} /> : null}
      <div
        className={`flex-1 bg-stone-50 ${
          state.auth ? "ml-96" : ""
        } md:ml-0 transition-all duration-200 ease-in-out`}
      >
        {state.auth ? <Header toggleSidebar={toggleSidebar} /> : null}
        <Routes>
          <Route
            path="/auth"
            element={state.auth === true ? <Navigate to={"/"} /> : <Auth />}
          />
          <Route
            path="/reset"
            element={state.auth === true ? <Navigate to={"/"} /> : <Reset />}
          />
          <Route
            path="/password"
            element={
              state.auth === true ? <Navigate to={"/"} /> : <ResetPassword />
            }
          />
          <Route
            exact
            path="/"
            element={
              state.auth === false ? <Navigate to={"/auth"} /> : <Home />
            }
          />
          <Route
            exact
            path="/open"
            element={
              state.auth === false ? <Navigate to={"/auth"} /> : <OpenTickets />
            }
          />
          <Route
            exact
            path="/scheduled"
            element={
              state.auth === false ? (
                <Navigate to={"/auth"} />
              ) : (
                <ScheduledTickets />
              )
            }
          />
          <Route
            exact
            path="/active"
            element={
              state.auth === false ? (
                <Navigate to={"/auth"} />
              ) : (
                <ActiveTickets />
              )
            }
          />
          <Route
            exact
            path="/completed"
            element={
              state.auth === false ? (
                <Navigate to={"/auth"} />
              ) : (
                <CompletedTickets />
              )
            }
          />
        </Routes>
        {state.newTaskIsOpen &&
          (state.auth === false ? <Navigate to={"/auth"} /> : <NewTask />)}
        {state.auth === true ? <Footer /> : null}
      </div>
      {state.loading && <Loading />}
    </div>
  );
}

export default React.memo(App);
