import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/css/index.css";
import App from "./App";
import "./i18n";
import { BrowserRouter as Router } from "react-router-dom";
import Store from "./Store";
import { CookiesProvider } from "react-cookie";

import reportWebVitals from "./reportWebVitals";
import Loading from "./components/Loading";

ReactDOM.render(
  <Store>
    <React.StrictMode>
      <Suspense fallback={<Loading />}>
        <Router>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </Router>
      </Suspense>
    </React.StrictMode>
  </Store>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
