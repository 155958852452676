import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../Store";

// import Assets
import PapLogo from "../assets/images/pap_logo.png";

function Header({ toggleSidebar }) {
  const [state, setState] = React.useContext(AppContext);

  return (
    <header
      className={
        "bg-stone-50 z-1000 sticky top-0 w-full py-4 px-8 md:px-4 shadow-lg flex flex-row items-center justify-between"
      }
    >
      <Link to="/">
        <img
          src={PapLogo}
          className={"w-auto h-16"}
          alt="Papakonstadinou IT Applications and Services"
        />
      </Link>
      <div className={"flex flex-row items-center justify-around w-24"}>
        {state.user && state.user.new_task[0] === "Yes" && (
          <button
            onClick={() => {
              setState({ ...state, newTaskIsOpen: !state.newTaskIsOpen });
            }}
            className={"text-xl flex flex-row items-center justify-center"}
          >
            <ion-icon name="duplicate-outline"></ion-icon>
          </button>
        )}
        {/* <button className={"text-xl flex flex-row items-center justify-center"}>
          <ion-icon name="notifications-outline"></ion-icon>
        </button> */}
        <button
          className={
            "ml-4 text-4xl md:flex hidden flex-row items-center justify-center"
          }
          onClick={toggleSidebar}
        >
          {state.isOpen ? (
            <ion-icon name="close-outline"></ion-icon>
          ) : (
            <ion-icon name="menu-outline"></ion-icon>
          )}
        </button>
      </div>
    </header>
  );
}

export default React.memo(Header);
