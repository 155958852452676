import React from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../Store";

// import components
import ListItem from "../components/ListItem";

export default function ActiveTickets() {
  const { t } = useTranslation();
  const [state, setState] = React.useContext(AppContext);

  return (
    <>
      <main className={"py-16 px-8"}>
        <h1 className={"text-4xl font-light"}>{t("activeTickets")}</h1>
        <h2 className={"text-sm font-light mb-20"}>{t("activeTicketsExp")}</h2>
        {state.tickets && state.tickets.active.length > 0 ? (
          Object.keys(state.tickets.active).map((key) => {
            return (
              <ListItem
                type={"active"}
                item={state.tickets.active[key]}
                key={key}
              />
            );
          })
        ) : (
          <p>{t("noTickets")}</p>
        )}
      </main>
    </>
  );
}
