import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../Store";
import { useTranslation } from "react-i18next";

// import Components
import Notification from "../components/Notification";
import Loading from "../components/Loading";

// import Assets
import PapLogo from "../assets/images/pap_logo.png";

function Auth() {
  const { t } = useTranslation();
  const [state, setState] = React.useContext(AppContext);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const validateLogin = (e) => {
    setState({ ...state, loading: true });
    e.preventDefault();
    let formData = new FormData();

    formData.append("email", email);
    formData.append("password", password);

    axios
      .post(
        process.env.NODE_ENV !== "development"
          ? `${process.env.REACT_APP_PROD_URL}/auth.php`
          : `${process.env.REACT_APP_DEV_URL}/auth.php`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "Allow-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setState({ ...state, loading: false });

        if (res.data.status === "success") {
          const date = new Date();
          date.setDate(date.getDate() + 30);

          localStorage.setItem("auth", true);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          setState({ ...state, auth: true, user: res.data });
        } else {
          setState({
            ...state,
            notification: {
              type: "error",
              error:
                res.data.message === "invalid_email"
                  ? t("invalidEmail")
                  : res.data.message === "invalid_password"
                  ? t("invalidPassword")
                  : res.data.error,
              visible: true,
            },
          });
        }
      });
  };

  return (
    <div className={"h-screen"}>
      <div className="h-10 rounded-full p-4">
        <button
          onClick={() => setState({ ...state, language: "el" })}
          className={`hover:bg-white ${
            state.language === "el" ? "bg-zinc-200" : ""
          } hover:text-gray-800 p-2 rounded-full w-10 h-10`}
        >
          ΕΛ
        </button>
        <button
          onClick={() => setState({ ...state, language: "en" })}
          className={`hover:bg-white ${
            state.language === "en" ? "bg-zinc-200" : ""
          } hover:text-gray-800 p-2 rounded-full w-10 h-10`}
        >
          EN
        </button>
      </div>
      <main
        className={
          "mt-20 bg-stone-50 w-screen flex flex-col items-center justify-center"
        }
      >
        <img
          src={PapLogo}
          className={"h-24 w-auto"}
          alt="Papakonstadinou IT Applications and Services"
        />
        <div
          className={
            "w-1/4 lg:w-1/2 md:w-11/12 h-auto p-4 bg-white rounded shadow-lg mt-12"
          }
        >
          <form
            onSubmit={validateLogin}
            className={"flex flex-col items-center justify-around"}
          >
            <ion-icon
              style={{ fontSize: "6rem", color: "rgb(31 41 55)" }}
              name="person-circle"
            ></ion-icon>
            <legend className="text-xl font-light mb-4 mt-2">
              {t("loginLegend")}
            </legend>
            <input
              type="email"
              placeholder={t("loginEmailField")}
              value={email}
              className={
                "text-sm p-2 border bg-stone-50 focus:outline-0 focus:shadow-md mb-4 border-stone-400 w-5/6 my-4 rounded transition-all duration-200 ease-in-out"
              }
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder={t("loginPasswordField")}
              value={password}
              className={
                "text-sm p-2 border bg-stone-50 focus:outline-0 focus:shadow-md mb-4 border-stone-400 w-5/6 my-4 rounded transition-all duration-200 ease-in-out"
              }
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Link className="my-4 text-sm" to="/reset">
              {t("loginForgotPassword")}
            </Link>
            <input
              type="submit"
              value={t("loginSubmitField")}
              className={
                "px-4 py-2 rounded bg-gray-800 text-white cursor-pointer hover:opacity-75 transition-all duration-200"
              }
            />
          </form>
        </div>
      </main>
      {state.notification.visible && <Notification />}
      {state.loading && <Loading />}
    </div>
  );
}

export default React.memo(Auth);
