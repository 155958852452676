import React from "react";

function Loading() {
  return (
    <div
      className={
        "flex z-10000 flex-row items-center justify-center fixed top-0 left-0 w-screen h-full bg-gray-900 bg-opacity-30"
      }
    >
      <div className="loader"></div>
    </div>
  );
}

export default React.memo(Loading);
