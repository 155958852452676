import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";

import PapLogo from "../assets/images/pap_logo.png";

// import components
import Notification from "../components/Notification";
import { useTranslation } from "react-i18next";

export default function Reset({
  auth,
  setAuth,
  setUser,
  language,
  setLanguage,
}) {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");

  const [notification, setNotification] = useState({
    type: null,
    error: null,
    visible: false,
  });

  const validateReset = (e) => {
    e.preventDefault();
    let formData = new FormData();

    formData.append("email", email);

    axios
      .post(
        process.env.NODE_ENV !== "development"
          ? `${process.env.REACT_APP_PROD_URL}/new_password.php`
          : `${process.env.REACT_APP_DEV_URL}/forgot_password.php`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "Allow-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setNotification({
            type: res.data.status,
            error: t(res.data.message),
            visible: true,
          });
        } else {
          setNotification({
            type: res.data.status,
            error:
              res.data.message === "wrong email"
                ? "Η διεύθυνση email δεν είναι έγκυρη. Παρακαλώ προσπαθήστε ξανά."
                : res.data.message === "wrong password"
                ? "Ο κωδικός πρόσβασης δεν είναι έγκυρος. Παρακαλώ προσπαθήστε ξανά."
                : "Υπήρξε πρόβλημα κατά την είσοδο. Παρακαλώ προσπαθήστε ξανά αργότερα.",
            visible: true,
          });
        }
      });
  };

  if (auth) {
    return <Navigate to="/" />;
  }

  return (
    <div className={"h-screen"}>
      <div className="h-10 rounded-full p-4">
        <button
          onClick={() => setLanguage("el-GR")}
          className={`hover:bg-white ${
            language === "el-GR" ? "bg-zinc-200" : ""
          } hover:text-gray-800 p-2 rounded-full w-10 h-10`}
        >
          ΕΛ
        </button>
        <button
          onClick={() => setLanguage("en-US")}
          className={`hover:bg-white ${
            language === "en-US" ? "bg-zinc-200" : ""
          } hover:text-gray-800 p-2 rounded-full w-10 h-10`}
        >
          EN
        </button>
      </div>
      <main
        className={
          "mt-20 bg-stone-50 w-screen flex flex-col items-center justify-center"
        }
      >
        <img
          src={PapLogo}
          className={"h-24 w-auto"}
          alt="Papakonstadinou IT Applications and Services"
        />
        <div
          className={
            "w-1/4 lg:w-1/2 md:w-11/12 h-auto p-4 bg-white rounded shadow-lg mt-12"
          }
        >
          <form
            onSubmit={validateReset}
            className={"flex flex-col items-center justify-around"}
          >
            <Link
              className={"text-sm flex flex-row items-center self-start mb-4"}
              to={"/auth"}
            >
              <ion-icon name="return-up-back-outline"></ion-icon>
              <span>{t("backButtonText")}</span>
            </Link>
            {/*<ion-icon style={{fontSize: "6rem", color: "rgb(31 41 55)"}} name="person-circle"></ion-icon>*/}
            <legend className="text-xl font-light mb-4 mt-2">
              {t("resetPasswordLegend")}
            </legend>
            <input
              type="email"
              placeholder={t("loginEmailField")}
              value={email}
              className={
                "text-sm p-2 border bg-stone-50 focus:outline-0 focus:shadow-md mb-4 border-stone-400 w-5/6 my-4 rounded transition-all duration-200 ease-in-out"
              }
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {/*<input*/}
            {/*  type="password"*/}
            {/*  placeholder={t("loginPasswordField")}*/}
            {/*  value={password}*/}
            {/*  className={*/}
            {/*    "text-sm p-2 border bg-stone-50 focus:outline-0 focus:shadow-md mb-4 border-stone-400 w-5/6 my-4 rounded transition-all duration-200 ease-in-out"*/}
            {/*  }*/}
            {/*  onChange={(e) => setPassword(e.target.value)}*/}
            {/*  required*/}
            {/*/>*/}
            {/*<Link className="my-4 text-sm" to="/reset">*/}
            {/*  {t("loginForgotPassword")}*/}
            {/*</Link>*/}
            <input
              type="submit"
              value={t("resetPasswordSubmitField")}
              className={
                "px-4 py-2 rounded bg-gray-800 text-white cursor-pointer hover:opacity-75 transition-all duration-200"
              }
            />
          </form>
        </div>
      </main>
      {notification.visible && (
        <Notification
          notification={notification}
          setNotification={setNotification}
        />
      )}
    </div>
  );
}
