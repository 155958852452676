import React from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../Store";

// import components
import ListItem from "../components/ListItem";

export default function ScheduledTickets() {
  const { t } = useTranslation();
  const [state, setState] = React.useContext(AppContext);

  return (
    <>
      <main className={"py-16 px-8"}>
        <h1 className={"text-4xl font-light"}>{t("scheduledTickets")}</h1>
        <h2 className={"text-sm font-light mb-20"}>
          {t("scheduledTicketsExp")}
        </h2>
        {state.tickets && state.tickets.scheduled.length > 0 ? (
          Object.keys(state.tickets.scheduled).map((key) => {
            return (
              <ListItem
                type={"scheduled"}
                item={state.tickets.scheduled[key]}
                key={key}
              />
            );
          })
        ) : (
          <p>{t("noTickets")}</p>
        )}
      </main>
    </>
  );
}
