import React from "react";
import { useTranslation } from "react-i18next";
import { Chart } from "react-google-charts";
import { AppContext } from "../Store";
import moment from "moment";

function Home() {
  const { t } = useTranslation();
  const [state] = React.useContext(AppContext);

  const [tasksPerMonth, setTasksPerMonth] = React.useState(null);
  const [tasksPerCategory, setTasksPerCategory] = React.useState(null);
  const [technicianPerTask, setTechnicianPerTask] = React.useState(null);
  const [compUserPerTask, setCompUserPerTask] = React.useState(null);
  const [tasksPerMonthCharge, setTasksPerMonthCharge] = React.useState(null);

  // dummy but necessary
  const [key, setKey] = React.useState(false);
  const [key2, setKey2] = React.useState(false);
  const [key3, setKey3] = React.useState(false);
  const [key4, setKey4] = React.useState(false);
  const [key5, setKey5] = React.useState(false);
  const size = useWindowSize();
  // dummy but necessary

  function useWindowSize() {
    const [windowSize, setWindowSize] = React.useState({
      width: undefined,
      height: undefined,
    });
    React.useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
  }

  React.useEffect(() => {
    setKey((prevKey) => !prevKey);
    setKey2((prevKey) => !prevKey);
    setKey3((prevKey) => !prevKey);
    setKey4((prevKey) => !prevKey);
    setKey5((prevKey) => !prevKey);
  }, [size.width]);

  React.useEffect(() => {
    if (state.initTickets) {
      const active = state.initTickets.active;
      const scheduled = state.initTickets.scheduled;
      const open = state.initTickets.open;
      const completed = state.initTickets.completed;

      let tasksPerMonthTmp = {
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0,
      };

      let tasksPerMonthChargeTmp = {
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0,
      };

      let tasksPerCategoryTmp = [];
      let technicianPerTask = [];
      let userPerTaskTmp = [];

      active.forEach((item) => {
        tasksPerMonthTmp = getMonthStat(tasksPerMonthTmp, item);
        tasksPerCategoryTmp.push(item[13]);
        technicianPerTask.push(item[3]);
        userPerTaskTmp.push(item[8] + " " + item[9]);
      });

      scheduled.forEach((item) => {
        tasksPerMonthTmp = getMonthStat(tasksPerMonthTmp, item);
        tasksPerCategoryTmp.push(item[13]);
        technicianPerTask.push(item[3]);
        userPerTaskTmp.push(item[8] + " " + item[9]);
      });

      open.forEach((item) => {
        tasksPerMonthTmp = getMonthStat(tasksPerMonthTmp, item);
        tasksPerCategoryTmp.push(item[13]);
        technicianPerTask.push(item[3]);
        userPerTaskTmp.push(item[8] + " " + item[9]);
      });

      completed.forEach((item) => {
        tasksPerMonthTmp = getMonthStat(tasksPerMonthTmp, item);
        tasksPerCategoryTmp.push(item[13]);
        technicianPerTask.push(item[3]);
        userPerTaskTmp.push(item[8] + " " + item[9]);
        tasksPerMonthChargeTmp = getMonthChargeStat(
          tasksPerMonthChargeTmp,
          item
        );
      });

      let map = {};

      for (let item of tasksPerCategoryTmp) {
        if (!map[item]) map[item] = 0;
        map[item]++;
      }

      let result = Object.entries(map);

      let map2 = {};

      for (let item of technicianPerTask) {
        if (!map2[item]) map2[item] = 0;
        map2[item]++;
      }

      let result2 = Object.entries(map2);

      let map3 = {};

      for (let item of userPerTaskTmp) {
        if (!map3[item]) map3[item] = 0;
        map3[item]++;
      }

      let result3 = Object.entries(map3);

      let finalizingTasksPerCategory = [["Task", "Category"]];

      let finalizingTechnicianPerCategory = [["Technician", "Tasks"]];

      let finalizingCompUserPerTask = [["Company user", "Αιτήματα"]];

      result.forEach((item) => {
        finalizingTasksPerCategory.push(item);
      });
      result2.forEach((item) => {
        finalizingTechnicianPerCategory.push(item);
      });
      result3.forEach((item) => {
        finalizingCompUserPerTask.push(item);
      });

      setTasksPerMonth(tasksPerMonthTmp);
      setTasksPerCategory(finalizingTasksPerCategory);
      setTechnicianPerTask(finalizingTechnicianPerCategory);
      setCompUserPerTask(finalizingCompUserPerTask);
      setTasksPerMonthCharge(tasksPerMonthChargeTmp);
    }
  }, [state.initTickets]);

  const getMonthStat = (tasksPerMonthTmp, item) => {
    const date = moment(item[6], "YYYY/MM/DD");

    switch (date.format("MMMM")) {
      case "January":
        tasksPerMonthTmp.january += 1;
        break;
      case "February":
        tasksPerMonthTmp.february += 1;
        break;
      case "March":
        tasksPerMonthTmp.march += 1;
        break;
      case "April":
        tasksPerMonthTmp.april += 1;
        break;
      case "May":
        tasksPerMonthTmp.may += 1;
        break;
      case "June":
        tasksPerMonthTmp.june += 1;
        break;
      case "July":
        tasksPerMonthTmp.july += 1;
        break;
      case "August":
        tasksPerMonthTmp.august += 1;
        break;
      case "September":
        tasksPerMonthTmp.september += 1;
        break;
      case "October":
        tasksPerMonthTmp.october += 1;
        break;
      case "November":
        tasksPerMonthTmp.november += 1;
        break;
      case "December":
        tasksPerMonthTmp.december += 1;
        break;
    }

    return tasksPerMonthTmp;
  };

  const getMonthChargeStat = (tasksPerMonthChargeTmp, item) => {
    const date = moment(item[6], "YYYY/MM/DD");

    switch (date.format("MMMM")) {
      case "January":
        tasksPerMonthChargeTmp.january += parseFloat(item[20]);
        break;
      case "February":
        tasksPerMonthChargeTmp.february += parseFloat(item[20]);
        break;
      case "March":
        tasksPerMonthChargeTmp.march += parseFloat(item[20]);
        break;
      case "April":
        tasksPerMonthChargeTmp.april += parseFloat(item[20]);
        break;
      case "May":
        tasksPerMonthChargeTmp.may += parseFloat(item[20]);
        break;
      case "June":
        tasksPerMonthChargeTmp.june += parseFloat(item[20]);
        break;
      case "July":
        tasksPerMonthChargeTmp.july += parseFloat(item[20]);
        break;
      case "August":
        tasksPerMonthChargeTmp.august += parseFloat(item[20]);
        break;
      case "September":
        tasksPerMonthChargeTmp.september += parseFloat(item[20]);
        break;
      case "October":
        tasksPerMonthChargeTmp.october += parseFloat(item[20]);
        break;
      case "November":
        tasksPerMonthChargeTmp.november += parseFloat(item[20]);
        break;
      case "December":
        tasksPerMonthChargeTmp.december += parseFloat(item[20]);
        break;
    }

    return tasksPerMonthChargeTmp;
  };

  return (
    <>
      <main className={"py-16 px-8"}>
        <h1 className={"text-4xl font-light mb-20"}>Dashboard</h1>
        <div className={"w-11/12 m-auto"}>
          <div className={"w-full my-4 rounded shadow-xl"}>
            {tasksPerCategory ? (
              <Chart
                key={key4}
                chartType="PieChart"
                data={tasksPerCategory ? tasksPerCategory : null}
                options={{
                  title: t("reqPerCategory"),
                  legend: { position: "bottom" },
                }}
                width="100%"
                height="500px"
              />
            ) : null}
          </div>
          <div className={"w-full my-4 rounded shadow-xl"}>
            {tasksPerMonth ? (
              <Chart
                key={key}
                chartType="LineChart"
                data={[
                  ["Element", "Αιτήματα", { role: "style" }],
                  [
                    t("january"),
                    tasksPerMonth ? tasksPerMonth.january : 0,
                    "#164e63",
                  ],
                  [
                    t("february"),
                    tasksPerMonth ? tasksPerMonth.february : 0,
                    "#164e63",
                  ],
                  [
                    t("march"),
                    tasksPerMonth ? tasksPerMonth.march : 0,
                    "#164e63",
                  ],
                  [
                    t("april"),
                    tasksPerMonth ? tasksPerMonth.april : 0,
                    "#164e63",
                  ],
                  [t("may"), tasksPerMonth ? tasksPerMonth.may : 0, "#164e63"],
                  [
                    t("june"),
                    tasksPerMonth ? tasksPerMonth.june : 0,
                    "#164e63",
                  ],
                  [
                    t("july"),
                    tasksPerMonth ? tasksPerMonth.july : 0,
                    "#164e63",
                  ],
                  [
                    t("august"),
                    tasksPerMonth ? tasksPerMonth.august : 0,
                    "#164e63",
                  ],
                  [
                    t("september"),
                    tasksPerMonth ? tasksPerMonth.september : 0,
                    "#164e63",
                  ],
                  [
                    t("october"),
                    tasksPerMonth ? tasksPerMonth.october : 0,
                    "#164e63",
                  ],
                  [
                    t("november"),
                    tasksPerMonth ? tasksPerMonth.november : 0,
                    "#164e63",
                  ],
                  [
                    t("december"),
                    tasksPerMonth ? tasksPerMonth.december : 0,
                    "#164e63",
                  ],
                ]}
                options={{
                  title: t("reqPerMonth"),
                  curveType: "function",
                  legend: { position: "bottom" },
                }}
                width="100%"
                height="500px"
                legendToggle
              />
            ) : null}
          </div>
          <div className={"w-full my-4 rounded shadow-xl"}>
            {tasksPerMonthCharge ? (
              <Chart
                key={key2}
                chartType="LineChart"
                data={[
                  ["Element", "Αιτήματα", { role: "style" }],
                  [
                    t("january"),
                    tasksPerMonthCharge ? tasksPerMonthCharge.january : 0,
                    "#164e63",
                  ],
                  [
                    t("february"),
                    tasksPerMonthCharge ? tasksPerMonthCharge.february : 0,
                    "#164e63",
                  ],
                  [
                    t("march"),
                    tasksPerMonthCharge ? tasksPerMonthCharge.march : 0,
                    "#164e63",
                  ],
                  [
                    t("april"),
                    tasksPerMonthCharge ? tasksPerMonthCharge.april : 0,
                    "#164e63",
                  ],
                  [
                    t("may"),
                    tasksPerMonthCharge ? tasksPerMonthCharge.may : 0,
                    "#164e63",
                  ],
                  [
                    t("june"),
                    tasksPerMonthCharge ? tasksPerMonthCharge.june : 0,
                    "#164e63",
                  ],
                  [
                    t("july"),
                    tasksPerMonthCharge ? tasksPerMonthCharge.july : 0,
                    "#164e63",
                  ],
                  [
                    t("august"),
                    tasksPerMonthCharge ? tasksPerMonthCharge.august : 0,
                    "#164e63",
                  ],
                  [
                    t("september"),
                    tasksPerMonthCharge ? tasksPerMonthCharge.september : 0,
                    "#164e63",
                  ],
                  [
                    t("october"),
                    tasksPerMonthCharge ? tasksPerMonthCharge.october : 0,
                    "#164e63",
                  ],
                  [
                    t("november"),
                    tasksPerMonthCharge ? tasksPerMonthCharge.november : 0,
                    "#164e63",
                  ],
                  [
                    t("december"),
                    tasksPerMonthCharge ? tasksPerMonthCharge.december : 0,
                    "#164e63",
                  ],
                ]}
                options={{
                  title: t("valuePerMonth"),
                  curveType: "function",
                  legend: { position: "bottom" },
                }}
                width="100%"
                height="500px"
                legendToggle
              />
            ) : null}
          </div>
          <div className={"w-full my-4 rounded shadow-xl"}>
            {technicianPerTask ? (
              <Chart
                key={key3}
                chartType="ColumnChart"
                data={technicianPerTask ? technicianPerTask : null}
                options={{
                  title: t("reqPerTechnician"),
                  legend: { position: "right" },
                }}
                width="100%"
                height="500px"
              />
            ) : null}
          </div>

          <div className={"w-full my-4 rounded shadow-xl"}>
            {compUserPerTask && compUserPerTask.length > 2 ? (
              <Chart
                key={key5}
                chartType="LineChart"
                data={compUserPerTask ? compUserPerTask : null}
                options={{
                  title: t("reqPerUser"),
                  curveType: "function",
                  legend: { position: "bottom" },
                }}
                width="100%"
                height="500px"
                legendToggle
              />
            ) : null}
          </div>
        </div>
      </main>
    </>
  );
}

export default React.memo(Home);
