import React from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../Store";

// import components
import ListItem from "../components/ListItem";

function CompletedTickets() {
  const { t } = useTranslation();
  const [state, setState] = React.useContext(AppContext);

  const [destructured, setDestructured] = React.useState(null);
  const [charges, setCharges] = React.useState(null);
  const [counters, setCounters] = React.useState(null);

  const [toBeInvoicedVisible, setToBeInvoicedVisible] = React.useState(false);
  const [completedVisible, setCompletedVisible] = React.useState(false);
  const [invoicedVisible, setInvoicedVisible] = React.useState(false);

  React.useEffect(() => {
    if (state.tickets) {
      let toBeInvoicedLocal = [];
      let completedLocal = [];
      let invoicedLocal = [];
      let toBeInvoicedLocalCharge = 0;
      let completedLocalCharge = 0;
      let invoicedLocalCharge = 0;
      let toBeInvoicedLocalCounter = 0;
      let completedLocalCounter = 0;
      let invoicedLocalCounter = 0;

      state.tickets.completed.forEach((item) => {
        if (item[4] === "Ολοκληρώθηκε") {
          completedLocal.push(item);
          completedLocalCharge += parseFloat(item[20]);
          completedLocalCounter++;
        } else if (item[4] === "Προς τιμολόγηση") {
          if (JSON.parse(item[21]) <= 0) {
            completedLocal.push(item);
            completedLocalCharge += parseFloat(item[20]);
            completedLocalCounter++;
          } else {
            toBeInvoicedLocal.push(item);
            toBeInvoicedLocalCharge += parseFloat(item[20]);
            toBeInvoicedLocalCounter++;
          }
        } else if (item[4] === "Τιμολογήθηκε") {
          invoicedLocal.push(item);
          invoicedLocalCharge += parseFloat(item[20]);
          invoicedLocalCounter++;
        }
      });

      const data = {
        toBeInvoiced: toBeInvoicedLocal,
        completed: completedLocal,
        invoiced: invoicedLocal,
      };

      const charges = {
        toBeInvoicedCharge: (
          Math.round((toBeInvoicedLocalCharge + Number.EPSILON) * 100) / 100
        ).toFixed(2),
        completedCharge: (
          Math.round((completedLocalCharge + Number.EPSILON) * 100) / 100
        ).toFixed(2),
        invoicedCharge: (
          Math.round((invoicedLocalCharge + Number.EPSILON) * 100) / 100
        ).toFixed(2),
      };

      const counters = {
        toBeInvoicedCounter: toBeInvoicedLocalCounter,
        completedCounter: completedLocalCounter,
        invoicedCounter: invoicedLocalCounter,
      };

      setDestructured(data);
      setCharges(charges);
      setCounters(counters);
    }
  }, [state.tickets]);

  const toggleCompletedVisible = () => {
    setInvoicedVisible(false);
    setToBeInvoicedVisible(false);
    setCompletedVisible(!completedVisible);
  };

  const toggleToBeInvoicedVisible = () => {
    setCompletedVisible(false);
    setInvoicedVisible(false);
    setToBeInvoicedVisible(!toBeInvoicedVisible);
  };

  const toggleInvoicedVisible = () => {
    setToBeInvoicedVisible(false);
    setCompletedVisible(false);
    setInvoicedVisible(!invoicedVisible);
  };

  return (
    <>
      <main className={"py-16 px-8"}>
        <h1 className={"text-4xl font-light"}>{t("completedTickets")}</h1>
        <h2 className={"text-sm font-light mb-20"}>
          {t("completedTicketsExp")}
        </h2>
        {state.tickets ? (
          <>
            <section className={"mb-2"}>
              <div
                onClick={toggleCompletedVisible}
                className={
                  "flex cursor-pointer bg-zinc-100 p-4 rounded shadow-md flex-row items-center justify-between h-24"
                }
              >
                <div className={"flex flex-row items-center"}>
                  <h3 className={"text-xl"}>Ολοκληρώθηκε</h3>
                  <span className={"ml-2 font-semibold"}>
                    ({counters ? counters.completedCounter : 0})
                  </span>
                </div>
                <div className={"flex flex-row items-center justify-end"}>
                  <div className={"mr-2 flex flex-col items-end"}>
                    {state.user.contract === true ? (
                      <span>
                        {Math.trunc(state.tickets.timeTotalCompleted) +
                          " " +
                          t("hours")}{" "}
                        {(((state.tickets.timeTotalCompleted % 1) * 60) / 100)
                          .toFixed(2)
                          .replace("0", "")
                          .replace(".", "") !== "00"
                          ? ", " +
                            (
                              ((state.tickets.timeTotalCompleted % 1) * 60) /
                              100
                            )
                              .toFixed(2)
                              .replace("0", "")
                              .replace(".", "") +
                            " " +
                            t("minutes")
                          : null}
                      </span>
                    ) : (
                      <span>
                        {Math.trunc(
                          parseInt(state.tickets.timeTotalCompletedDate) / 60
                        ) +
                          " " +
                          t("hours")}{" "}
                        {(
                          (((parseInt(state.tickets.timeTotalCompletedDate) /
                            60) %
                            1) *
                            60) /
                          100
                        )
                          .toFixed(2)
                          .replace("0", "")
                          .replace(".", "") !== "00"
                          ? ", " +
                            (
                              (((parseInt(
                                state.tickets.timeTotalCompletedDate
                              ) /
                                60) %
                                1) *
                                60) /
                              100
                            )
                              .toFixed(2)
                              .replace("0", "")
                              .replace(".", "") +
                            " " +
                            t("minutes")
                          : null}
                      </span>
                    )}
                  </div>
                  <ion-icon name="add-outline"></ion-icon>
                </div>
              </div>
              <div
                className={`${completedVisible ? "visible" : "hidden"} h-auto`}
              >
                {destructured && destructured.completed.length > 0
                  ? Object.keys(destructured.completed).map((key) => {
                      return (
                        <ListItem
                          contract={state.user.contract}
                          type={"completed"}
                          item={destructured.completed[key]}
                          key={key}
                        />
                      );
                    })
                  : null}
              </div>
              <div className={"hidden"}>
                {charges && charges.completedCharge
                  ? charges.completedCharge
                  : null}
              </div>
            </section>
            <section className={"mb-2"}>
              <div
                onClick={toggleToBeInvoicedVisible}
                className={
                  "flex cursor-pointer bg-zinc-100 p-4 rounded shadow-md flex-row items-center justify-between h-24"
                }
              >
                <div className={"flex flex-row items-center"}>
                  <h3 className={"text-xl"}>Προς τιμολόγηση</h3>
                  <span className={"ml-2 font-semibold"}>
                    ({counters ? counters.toBeInvoicedCounter : 0})
                  </span>
                </div>
                <div className={"flex flex-row items-center justify-end"}>
                  <div className={"mr-2 flex flex-col items-end"}>
                    {state.user.contract === true ? (
                      <span>
                        {Math.trunc(state.tickets.timeTotalToBeInvoiced) +
                          " " +
                          t("hours")}{" "}
                        {(
                          ((state.tickets.timeTotalToBeInvoiced % 1) * 60) /
                          100
                        )
                          .toFixed(2)
                          .replace("0", "")
                          .replace(".", "") !== "00"
                          ? ", " +
                            (
                              ((state.tickets.timeTotalToBeInvoiced % 1) * 60) /
                              100
                            )
                              .toFixed(2)
                              .replace("0", "")
                              .replace(".", "") +
                            " " +
                            t("minutes")
                          : null}
                      </span>
                    ) : (
                      <span>
                        {Math.trunc(
                          parseInt(state.tickets.timeTotalToBeInvoicedDate) / 60
                        ) +
                          " " +
                          t("hours")}{" "}
                        {(
                          (((parseInt(state.tickets.timeTotalToBeInvoicedDate) /
                            60) %
                            1) *
                            60) /
                          100
                        )
                          .toFixed(2)
                          .replace("0", "")
                          .replace(".", "") !== "00"
                          ? ", " +
                            (
                              (((parseInt(
                                state.tickets.timeTotalToBeInvoicedDate
                              ) /
                                60) %
                                1) *
                                60) /
                              100
                            )
                              .toFixed(2)
                              .replace("0", "")
                              .replace(".", "") +
                            " " +
                            t("minutes")
                          : null}
                      </span>
                    )}
                    <div className={"flex flex-row items-center justify-end"}>
                      <span className={"font-semibold"}>
                        {charges && charges.toBeInvoicedCharge
                          ? charges.toBeInvoicedCharge
                          : null}
                      </span>
                      <span className={"ml-1"}>€</span>
                    </div>
                  </div>
                  <ion-icon name="add-outline"></ion-icon>
                </div>
              </div>
              <div
                className={`${
                  toBeInvoicedVisible ? "visible" : "hidden"
                } h-auto`}
              >
                {destructured && destructured.toBeInvoiced.length > 0
                  ? Object.keys(destructured.toBeInvoiced).map((key) => {
                      return (
                        <ListItem
                          contract={state.user.contract}
                          type={"completed"}
                          showValue={true}
                          item={destructured.toBeInvoiced[key]}
                          key={key}
                        />
                      );
                    })
                  : null}
              </div>
              <div className={"hidden"}>
                {charges && charges.toBeInvoicedCharge
                  ? charges.toBeInvoicedCharge
                  : null}
              </div>
            </section>
            <section className={"mb-2"}>
              <div
                onClick={toggleInvoicedVisible}
                className={
                  "flex cursor-pointer bg-zinc-100 p-4 rounded shadow-md flex-row items-center justify-between h-24"
                }
              >
                <div className={"flex flex-row items-center"}>
                  <h3 className={"text-xl"}>Τιμολογήθηκε</h3>
                  <span className={"ml-2 font-semibold"}>
                    ({counters ? counters.invoicedCounter : 0})
                  </span>
                </div>
                <div className={"flex flex-row items-center justify-end"}>
                  <div className={"mr-2 flex flex-col items-end"}>
                    {state.user.contract === true ? (
                      <span>
                        {Math.trunc(state.tickets.timeTotalInvoiced) +
                          " " +
                          t("hours")}{" "}
                        {(((state.tickets.timeTotalInvoiced % 1) * 60) / 100)
                          .toFixed(2)
                          .replace("0", "")
                          .replace(".", "") !== "00"
                          ? ", " +
                            (((state.tickets.timeTotalInvoiced % 1) * 60) / 100)
                              .toFixed(2)
                              .replace("0", "")
                              .replace(".", "") +
                            " " +
                            t("minutes")
                          : null}
                      </span>
                    ) : (
                      <span>
                        {Math.trunc(
                          parseInt(state.tickets.timeTotalInvoicedDate) / 60
                        ) +
                          " " +
                          t("hours")}{" "}
                        {(
                          (((parseInt(state.tickets.timeTotalInvoicedDate) /
                            60) %
                            1) *
                            60) /
                          100
                        )
                          .toFixed(2)
                          .replace("0", "")
                          .replace(".", "") !== "00"
                          ? ", " +
                            (
                              (((parseInt(state.tickets.timeTotalInvoicedDate) /
                                60) %
                                1) *
                                60) /
                              100
                            )
                              .toFixed(2)
                              .replace("0", "")
                              .replace(".", "") +
                            " " +
                            t("minutes")
                          : null}
                      </span>
                    )}
                    <div className={"flex flex-row items-center justify-end"}>
                      <span className={"font-semibold"}>
                        {charges && charges.invoicedCharge
                          ? charges.invoicedCharge
                          : null}
                      </span>
                      <span className={"ml-1"}>€</span>
                    </div>
                  </div>
                  <ion-icon name="add-outline"></ion-icon>
                </div>
              </div>
              <div
                className={`${invoicedVisible ? "visible" : "hidden"} h-auto`}
              >
                {destructured && destructured.invoiced.length > 0
                  ? Object.keys(destructured.invoiced).map((key) => {
                      return (
                        <ListItem
                          contract={state.user.contract}
                          type={"completed"}
                          showValue={true}
                          item={destructured.invoiced[key]}
                          key={key}
                        />
                      );
                    })
                  : null}
              </div>
              <div className={"hidden"}>
                {charges && charges.invoicedCharge
                  ? charges.invoicedCharge
                  : null}
              </div>
            </section>
          </>
        ) : null}
      </main>
    </>
  );
}

export default CompletedTickets;
