import React from "react";
import moment from "moment";
import { t } from "i18next";
import { AppContext } from "../Store";

function ListItem(props) {
  const { type, item, showValue } = props;
  const [state, setState] = React.useContext(AppContext);
  let bgColor =
    type === "open"
      ? "bg-red-500"
      : type === "scheduled"
      ? "bg-yellow-500"
      : type === "active"
      ? "bg-green-500"
      : type === "completed"
      ? "bg-blue-500"
      : "bg-gray-500";

  // Thelei veltistopoiisi
  const showInfo = (id) => {
    const taskItems = document.querySelectorAll(".task_item");

    taskItems.forEach((item) => {
      if (!item.classList.contains("hidden")) {
        if (item.id === `task-${id}`) {
          document.querySelector(`#task-${id}`).classList.add("hidden");
        }

        item.classList.add("hidden");
      } else {
        if (item.id === `task-${id}`) {
          document.querySelector(`#task-${id}`).classList.remove("hidden");
        }
      }
    });
  };

  const destructDateHours = (ticket) => {
    const ms = moment(ticket[7], "YYYY-MM-DD H:m").diff(
      moment(ticket[6], "YYYY-MM-DD H:m")
    );
    const d = moment.duration(ms);
    const s = Math.floor(d.asHours()) + "." + moment.utc(ms).format("mm");
    const hours = parseFloat(s);

    return (
      <td>
        {Math.trunc(hours) + " " + t("hours")}{" "}
        {(hours % 1).toFixed(2).replace("0", "").replace(".", "") !== "00"
          ? ", " +
            (hours % 1).toFixed(2).replace("0", "").replace(".", "") +
            " " +
            t("minutes")
          : null}
      </td>
    );
  };

  return (
    <>
      <button
        onClick={() => showInfo(item[15])}
        className={
          "bg-gray-800 text-stone-50 text-sm w-full h-20 md:h-auto my-1 shadow-lg rounded flex flex-row md:flex-col items-center justify-between md:justify-center p-4"
        }
      >
        <div
          className={
            "flex flex-row sm:flex-col items-center sm:items-start justify-start w-2/3 md:w-full"
          }
        >
          <p className={bgColor + " px-2 py-1 rounded-full font-bold italic"}>
            #{item[15]}
          </p>
          <div
            className={
              "flex flex-col items-start justify-center ml-8 sm:ml-0 sm:mt-2"
            }
          >
            <p className={"text-left font-bold"}>{item[2]}</p>
            <p className={"text-stone-400"}>
              {item[8]} {item[9]}
            </p>
          </div>
        </div>
        <div
          className={
            "flex flex-col items-end justify-center md:mt-2 w-1/3 md:w-full"
          }
        >
          <div className="flex flex-row items-center justify-end">
            <p className={"mr-4 text-left"}>
              {moment(item[6]).format("DD-MM-YYYY HH:mm")}
            </p>
            <ion-icon name="add-outline"></ion-icon>
          </div>
          {showValue && item && JSON.parse(item[20]) > 0 ? (
            <p>
              Αξία παροχής: <b>{JSON.parse(item[20]).toFixed(2)}</b>
              <span className="ml-1">€</span>
            </p>
          ) : null}
        </div>
      </button>
      <div
        id={`task-${item[15]}`}
        className={
          "w-full bg-stone-50 h-auto p-4 shadow-xl rounded hidden transition-all duration-500 task_item"
        }
      >
        <table
          style={{ borderSpacing: "1rem" }}
          className={"w-full border-separate text-sm"}
        >
          <tbody className={"tbody-taskInfo"}>
            <tr>
              <th>{t("ticketDateCreated")}</th>
              <td>{moment(item[6]).format("DD-MM-YYYY HH:mm")}</td>
            </tr>
            {type === "completed" ? (
              <tr>
                <th>{t("ticketDateEnded")}</th>
                <td>{moment(item[7]).format("DD-MM-YYYY HH:mm")}</td>
              </tr>
            ) : null}
            {type === "completed" ? (
              <tr>
                <th>{t("ticketDuration")}</th>
                {state.user.contract ? (
                  <td>
                    {Math.trunc(item[22]) + " " + t("hours")}{" "}
                    {(((item[22] % 1) * 60) / 100)
                      .toFixed(2)
                      .replace("0", "")
                      .replace(".", "") !== "00"
                      ? ", " +
                        (((item[22] % 1) * 60) / 100)
                          .toFixed(2)
                          .replace("0", "")
                          .replace(".", "") +
                        " " +
                        t("minutes")
                      : null}
                  </td>
                ) : (
                  destructDateHours(item)
                )}
              </tr>
            ) : null}
            <tr>
              <th>{t("ticketIssuer")}</th>
              <td>{item[1]}</td>
            </tr>
            <tr>
              <th>{t("ticketTechnician")}</th>
              <td>{item[3]}</td>
            </tr>
            <tr>
              <th>{t("ticketRegType")}</th>
              <td>{item[14]}</td>
            </tr>
            <tr>
              <th>{t("ticketCategory")}</th>
              <td>{item[12]}</td>
            </tr>
            <tr>
              <th>{t("ticketSubCategory")}</th>
              <td>{item[13]}</td>
            </tr>
            <tr>
              <th>{t("ticketDescription")}</th>
              <td>{item[11]}</td>
            </tr>
            {item[20] !== "0" ? (
              <tr>
                <th>{t("ticketValueOfService")}</th>
                <td>{Number.parseFloat(item[20]).toFixed(2)} €</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default React.memo(ListItem);
