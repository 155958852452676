import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "../Store";
import moment from "moment";

const d = new Date();
const year = d.getFullYear();

let tmpUsage = [];

for (let i = 2021; i <= year; i++) {
  tmpUsage.push(i);
}

function Sidebar({ toggleSidebar }) {
  const { t } = useTranslation();
  const [state, setState] = React.useContext(AppContext);

  const [filterTask, setFilterTask] = React.useState("");

  const logout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("auth");
    setState({
      ...state,
      auth: false,
      user: null,
      company: "all",
      use: 2022,
      tickets: null,
      initTickets: null,
      loading: false,
      notification: {
        type: null,
        error: null,
        visible: false,
      },
      isOpen: false,
      newTaskIsOpen: false,
    });
  };

  const handleSearchBar = (e) => {
    setFilterTask(e.target.value);
  };

  const clearSearchBar = () => {
    setFilterTask("");
  };

  React.useEffect(() => {
    if (filterTask.length > 2) {
      const initOpenTickets = state.initTickets.open;
      const initScheduledTickets = state.initTickets.scheduled;
      const initActiveTickets = state.initTickets.active;
      const initCompletedTickets = state.initTickets.completed;

      let data = [];
      let totalBilled = 0;
      let timeTotalCompleted = 0;
      let timeTotalToBeInvoiced = 0;
      let timeTotalInvoiced = 0;

      let timeTotalCompletedDate = 0;
      let timeTotalToBeInvoicedDate = 0;
      let timeTotalInvoicedDate = 0;

      const filteredOpenTickets = initOpenTickets.filter((ticket) =>
        ticket[15].includes(filterTask)
      );
      const filteredScheduledTickets = initScheduledTickets.filter((ticket) =>
        ticket[15].includes(filterTask)
      );
      const filteredActiveTickets = initActiveTickets.filter((ticket) =>
        ticket[15].includes(filterTask)
      );
      const filteredCompletedTickets = initCompletedTickets.filter((ticket) =>
        ticket[15].includes(filterTask)
      );

      filteredOpenTickets.forEach((ticket) => {
        totalBilled += parseFloat(ticket[20]);
      });
      filteredScheduledTickets.forEach((ticket) => {
        totalBilled += parseFloat(ticket[20]);
      });
      filteredActiveTickets.forEach((ticket) => {
        totalBilled += parseFloat(ticket[20]);
      });
      filteredCompletedTickets.forEach((ticket) => {
        const ms = moment(ticket[7], "YYYY-MM-DD H:m").diff(
          moment(ticket[6], "YYYY-MM-DD H:m")
        );
        const d = moment.duration(ms);
        const s = Math.floor(d.asHours()) + "." + moment.utc(ms).format("mm");
        const hours = parseFloat(s);

        if (ticket[4] !== "Ολοκληρώθηκε") {
          totalBilled += parseFloat(ticket[20]);

          if (ticket[4] === "Προς τιμολόγηση") {
            timeTotalToBeInvoiced += parseFloat(ticket[22]);
            timeTotalToBeInvoicedDate += hours;
          } else {
            timeTotalInvoiced += parseFloat(ticket[22]);
            timeTotalInvoicedDate += hours;
          }
        } else {
          timeTotalCompleted += parseFloat(ticket[22]);
          timeTotalCompletedDate += hours;
        }
      });

      data.open = filteredOpenTickets;
      data.scheduled = filteredScheduledTickets;
      data.active = filteredActiveTickets;
      data.completed = filteredCompletedTickets;
      data.totalBilled = (
        Math.round((totalBilled + Number.EPSILON) * 100) / 100
      ).toFixed(2);
      data.timeTotalCompleted = (
        Math.round((timeTotalCompleted + Number.EPSILON) * 100) / 100
      ).toFixed(2);
      data.timeTotalToBeInvoiced = (
        Math.round((timeTotalToBeInvoiced + Number.EPSILON) * 100) / 100
      ).toFixed(2);
      data.timeTotalInvoiced = (
        Math.round((timeTotalInvoiced + Number.EPSILON) * 100) / 100
      ).toFixed(2);
      data.timeTotalCompletedDate = (
        Math.round((timeTotalCompletedDate + Number.EPSILON) * 100) / 100
      ).toFixed(2);
      data.timeTotalToBeInvoicedDate = (
        Math.round((timeTotalToBeInvoicedDate + Number.EPSILON) * 100) / 100
      ).toFixed(2);
      data.timeTotalInvoicedDate = (
        Math.round((timeTotalInvoicedDate + Number.EPSILON) * 100) / 100
      ).toFixed(2);

      setState({ ...state, tickets: data });
    } else {
      setState({ ...state, tickets: state.initTickets });
    }
  }, [filterTask]);

  const toggleChangeCompany = (e) => {
    document.querySelector(".sidebar").classList.toggle("md:-translate-x-full");

    const curIsOpen = state.isOpen;

    setState({
      ...state,
      isOpen: !curIsOpen,
      company: e.target.value,
    });
  };

  const handleChangeUse = (e) => {
    document.querySelector(".sidebar").classList.toggle("md:-translate-x-full");

    const curIsOpen = state.isOpen;

    setState({
      ...state,
      isOpen: !curIsOpen,
      use: e.target.value,
      loading: true,
    });
  };

  return (
    <div className="sidebar text-stone-50 p-4 h-screen fixed inset-y-0 left-0 transform md:-translate-x-full translate-x-0 transition duration-200 ease-in-out z-10 w-96 md:w-full bg-gray-800 flex flex-col items-center justify-between">
      <div className={"w-full"}>
        <div className="w-full mb-20 flex flex-row items-center justify-end">
          <button onClick={toggleSidebar} className={"text-4xl md:flex hidden"}>
            <ion-icon name="close-outline"></ion-icon>
          </button>
        </div>
        <div className={"w-full mb-12 md:mb-6"}>
          <p className={"mb-2 text-xl font-semibold"}>{t("searchLabel")}</p>
          <div
            className={
              "flex flex-row items-center justify-between rounded h-12 bg-gray-700"
            }
          >
            <input
              onChange={handleSearchBar}
              id={"search"}
              name={"search"}
              value={filterTask}
              className={"rounded w-full h-12 bg-gray-700 p-4 focus:outline-0"}
              placeholder={t("searchInputPlaceholder")}
              type="text"
            />
            <button
              onClick={clearSearchBar}
              className={
                "mr-2 flex flex-column items-center justify-center text-xl text-gray-200"
              }
            >
              <ion-icon name="close-circle-outline"></ion-icon>
            </button>
          </div>
        </div>
        <div className={"w-full mb-8 md:mb-4"}>
          <p className={"mb-2 text-xl font-semibold"}>{t("use")}</p>
          <select
            value={state.use}
            onChange={(e) => handleChangeUse(e)}
            className={
              "w-full px-4 py-4 rounded border-0 focus:outline-0 bg-gray-700"
            }
          >
            {tmpUsage.map((item, i) => {
              return (
                <option key={i} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        <div className={"w-full mb-8 md:4"}>
          {state.user && state.user.companies_full.length > 1 ? (
            <>
              <p className={"mb-2 text-xl font-semibold"}>Επιλογή εταιρείας</p>
              <select
                value={state.company}
                onChange={(e) => {
                  toggleChangeCompany(e);
                }}
                className={
                  "w-full px-4 py-4 rounded border-0 focus:outline-0 bg-gray-700"
                }
              >
                <option value={"all"}>{t("allCompanies")}</option>
                {Object.keys(state.user.companies_full).map((key) => {
                  return (
                    <option key={key} value={state.user.companies_trdr[key]}>
                      {state.user.companies_full[key] +
                        " (" +
                        state.user.companies_trdr[key] +
                        ")"}
                    </option>
                  );
                })}
              </select>
            </>
          ) : (
            ""
          )}
        </div>
        <div>
          <NavLink
            onClick={toggleSidebar}
            end
            to="/"
            className={({ isActive }) =>
              `p-4 hover:bg-gray-700 ${
                isActive ? "bg-gray-700" : ""
              } flex flex-row items-center justify-between w-full text-xl rounded my-1`
            }
          >
            <span className={"font-semibold"}>{t("menuHome")}</span>
            <ion-icon
              style={{ color: "#d6d3d1" }}
              name="home-outline"
            ></ion-icon>
          </NavLink>
          <NavLink
            onClick={toggleSidebar}
            end
            to="/open"
            className={({ isActive }) =>
              `p-4 hover:bg-gray-700 ${
                isActive ? "bg-gray-700" : ""
              } flex flex-row items-center justify-between w-full text-xl rounded my-1`
            }
          >
            <span className={"font-semibold"}>
              {t("menuOpenTickets")} (
              {state.tickets ? state.tickets.open.length : 0})
            </span>
            <ion-icon
              style={{ color: "#f06451" }}
              name="list-outline"
            ></ion-icon>
          </NavLink>
          <NavLink
            onClick={toggleSidebar}
            end
            to="/scheduled"
            className={({ isActive }) =>
              `p-4 hover:bg-gray-700 ${
                isActive ? "bg-gray-700" : ""
              } flex flex-row items-center justify-between w-full text-xl rounded my-1`
            }
          >
            <span className={"font-semibold"}>
              {t("menuScheduledTickets")} (
              {state.tickets ? state.tickets.scheduled.length : 0})
            </span>
            <ion-icon
              style={{ color: "#f0b051" }}
              name="time-outline"
            ></ion-icon>
          </NavLink>
          <NavLink
            onClick={toggleSidebar}
            end
            to="/active"
            className={({ isActive }) =>
              `p-4 hover:bg-gray-700 ${
                isActive ? "bg-gray-700" : ""
              } flex flex-row items-center justify-between w-full text-xl rounded my-1`
            }
          >
            <span className={"font-semibold"}>
              {t("menuActiveTickets")} (
              {state.tickets ? state.tickets.active.length : 0})
            </span>
            <ion-icon
              style={{ color: "#399e5a" }}
              name="flash-outline"
            ></ion-icon>
          </NavLink>
          <NavLink
            onClick={toggleSidebar}
            end
            to="/completed"
            className={({ isActive }) =>
              `p-4 hover:bg-gray-700 ${
                isActive ? "bg-gray-700" : ""
              } flex flex-row items-center justify-between w-full text-xl rounded my-1`
            }
          >
            <span className={"font-semibold"}>
              {t("menuCompletedTickets")} (
              {state.tickets ? state.tickets.completed.length : 0})
            </span>
            <ion-icon
              style={{ color: "#5191f0" }}
              name="checkmark-outline"
            ></ion-icon>
          </NavLink>
        </div>
      </div>
      <div className={"w-full flex flex-row items-center justify-between p-4"}>
        <div className="bg-gray-700 h-10 rounded-full">
          <button
            onClick={() => setState({ ...state, language: "el" })}
            className={`hover:bg-white ${
              state.language === "el" ? "bg-gray-600" : ""
            } hover:text-gray-800 p-2 rounded-full w-10 h-10`}
          >
            ΕΛ
          </button>
          <button
            onClick={() => setState({ ...state, language: "en" })}
            className={`hover:bg-white ${
              state.language === "en" ? "bg-gray-600" : ""
            } hover:text-gray-800 p-2 rounded-full w-10 h-10`}
          >
            EN
          </button>
        </div>
        <button onClick={logout} className={"flex flex-row items-center"}>
          {t("logout")}
          <ion-icon
            style={{ fontSize: "1.75rem", marginLeft: "0.25rem" }}
            name="log-out-outline"
          ></ion-icon>
        </button>
      </div>
    </div>
  );
}

export default React.memo(Sidebar);
