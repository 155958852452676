import React, { useState } from "react";
import { t } from "i18next";
import axios from "axios";
import { AppContext } from "../Store";

import Notification from "./Notification";

export default function NewTask() {
  const [state, setState] = React.useContext(AppContext);

  const [subject, setSubject] = useState("");
  const [companyRef, setCompanyRef] = useState(0);
  const [category, setCategory] = useState("0");
  const [subCategory, setSubCategory] = useState("0");
  const [technician, setTechnician] = useState("0");
  const [description, setDescription] = useState("");

  const [companyTrdr, setCompanyTrdr] = useState(0);
  const [userPrsn, setUserPrsn] = useState(0);

  const closeNewTask = (e) => {
    if (e.target.id === "new-task-container") {
      const x = state.newTaskIsOpen;

      setState({ ...state, newTaskIsOpen: !x });
    }
  };

  const closeNewTaskForced = () => {
    setState({ ...state, newTaskIsOpen: false });
  };

  const submitNewTicket = (e) => {
    e.preventDefault();

    let formData = new FormData();

    formData.append("subject", subject);
    formData.append("category", category);
    formData.append("sub-category", subCategory);
    formData.append("technician", technician);
    formData.append("description", description);
    formData.append("company_trdr", state.user.companies_trdr[companyTrdr]);
    formData.append("user_prsn", state.user.users_prsn[userPrsn]);

    if (subject === "") {
      setState({
        ...state,
        notification: {
          type: "error",
          error: t("newTaskSubjectIsRequired"),
          visible: true,
        },
      });
    } else if (category === "0") {
      setState({
        ...state,
        notification: {
          type: "error",
          error: t("newTaskCategoryIsRequired"),
          visible: true,
        },
      });
    } else if (subCategory === "0") {
      setState({
        ...state,
        notification: {
          type: "error",
          error: t("newTaskSubCategoryIsRequired"),
          visible: true,
        },
      });
    } else if (technician === "0") {
      setState({
        ...state,
        notification: {
          type: "error",
          error: t("newTaskTechnicianIsRequired"),
          visible: true,
        },
      });
    } else if (description === "") {
      setState({
        ...state,
        notification: {
          type: "error",
          error: t("newTaskDescriptionIsRequired"),
          visible: true,
        },
      });
    } else {
      axios
        .post(
          process.env.NODE_ENV !== "development"
            ? `${process.env.REACT_APP_PROD_URL}/submit_ticket.php`
            : `${process.env.REACT_APP_DEV_URL}/submit_ticket.php`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              "Allow-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            setState({
              ...state,
              notification: {
                type: "success",
                error: t("newTaskSubmitted"),
                visible: true,
              },
            });
          } else {
            setState({
              ...state,
              notification: {
                type: "error",
                error: t("newTaskFailed"),
                visible: true,
              },
            });
          }
        });
    }
  };

  const handleCompanyChange = (e) => {
    setCompanyRef(e.target.value);
    setCompanyTrdr(JSON.parse(e.target.value));
    setUserPrsn(JSON.parse(e.target.value));
  };

  return (
    <>
      <div
        id="new-task-container"
        onClick={closeNewTask}
        className={
          "w-full z-3000 h-screen backdrop-blur-sm bg-black bg-opacity-40 fixed top-0 left-0"
        }
      >
        <div
          id="new-task-content"
          className={
            "transition-all duration-200 ease-in-out w-1/2 md:w-10/12 sm:w-11/12 bg-stone-50 rounded shadow-xl h-auto m-auto mt-12 md:mt-16"
          }
        >
          <div
            className={
              "flex flex-row items-center justify-between border-b border-stone-200 mx-8 py-4 mb-4"
            }
          >
            <h1 className={"text-3xl font-light md:text-2xl sm:text-xl"}>
              {t("newTicketTitle")}
            </h1>
          </div>
          <form
            onSubmit={submitNewTicket}
            className={"w-full p-8 flex flex-col items-start justify-center"}
            method="POST"
          >
            <label className={"w-full"} htmlFor="subject">
              {t("newTicketSubject")}
              <input
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
                placeholder={"π.χ. Πρόβλημα με εξοπλισμό"}
                type="text"
                className={
                  "text-sm border bg-stone-50 focus:outline-0 focus:shadow-md mb-4 border-stone-400 w-full px-2 py-1 rounded"
                }
                id="subject"
                name="subject"
              />
            </label>
            {state.user.companies_full.length > 1 && (
              <label className={"w-full"} htmlFor="company">
                {t("ticketCompanyRef")}
                <select
                  onChange={handleCompanyChange}
                  className={
                    "text-sm border bg-stone-50 focus:outline-0 focus:shadow-md mb-4 border-stone-400 w-full px-2 py-1 rounded"
                  }
                  id="company"
                  name="company"
                >
                  {Object.keys(state.user.companies_full).map((key) => {
                    return (
                      <option key={key} value={key}>
                        {state.user.companies_full[key]}
                      </option>
                    );
                  })}
                </select>
              </label>
            )}
            <label className={"w-full"} htmlFor="category">
              {t("newTicketCategory")}
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className={
                  "text-sm border bg-stone-50 focus:outline-0 focus:shadow-md mb-4 border-stone-400 w-full px-2 py-1 rounded"
                }
                id="category"
                name="category"
              >
                <option className={"text-gray-400"} value={0}>
                  -- {t("newTicketCategory0")}
                </option>
                <option value={101}>{t("newTicketCategory101")}</option>
                <option value={102}>{t("newTicketCategory102")}</option>
                <option value={103}>{t("newTicketCategory103")}</option>
              </select>
            </label>
            <label className={"w-full"} htmlFor="sub-category">
              {t("newTicketSubCategory")}
              <select
                value={subCategory}
                onChange={(e) => setSubCategory(e.target.value)}
                className={
                  "text-sm border bg-stone-50 focus:outline-0 focus:shadow-md mb-4 border-stone-400 w-full px-2 py-1 rounded"
                }
                id="sub-category"
                name="sub-category"
              >
                <option className={"text-gray-400"} value={0}>
                  -- {t("newTicketSubCategory0")}
                </option>
                <option value={101}>{t("newTicketSubCategory101")}</option>
                <option value={102}>{t("newTicketSubCategory102")}</option>
                <option value={103}>{t("newTicketSubCategory103")}</option>
                <option value={104}>{t("newTicketSubCategory104")}</option>
                <option value={105}>{t("newTicketSubCategory105")}</option>
                <option value={106}>{t("newTicketSubCategory106")}</option>
                <option value={107}>{t("newTicketSubCategory107")}</option>
                <option value={108}>{t("newTicketSubCategory108")}</option>
                <option value={109}>{t("newTicketSubCategory109")}</option>
                <option value={110}>{t("newTicketSubCategory110")}</option>
                <option value={111}>{t("newTicketSubCategory111")}</option>
                <option value={112}>{t("newTicketSubCategory112")}</option>
                <option value={113}>{t("newTicketSubCategory113")}</option>
                <option value={114}>{t("newTicketSubCategory114")}</option>
                <option value={115}>{t("newTicketSubCategory115")}</option>
                <option value={116}>{t("newTicketSubCategory116")}</option>
                <option value={117}>{t("newTicketSubCategory117")}</option>
                <option value={121}>{t("newTicketSubCategory121")}</option>
                <option value={122}>{t("newTicketSubCategory122")}</option>
                <option value={129}>{t("newTicketSubCategory129")}</option>
                <option value={130}>{t("newTicketSubCategory130")}</option>
                <option value={134}>{t("newTicketSubCategory134")}</option>
                <option value={135}>{t("newTicketSubCategory135")}</option>
                <option value={136}>{t("newTicketSubCategory136")}</option>
                <option value={137}>{t("newTicketSubCategory137")}</option>
                <option value={139}>{t("newTicketSubCategory139")}</option>
              </select>
            </label>
            <label className={"w-full"} htmlFor="technician">
              {t("newTicketTechnician")}
              <select
                value={technician}
                onChange={(e) => setTechnician(e.target.value)}
                className={
                  "text-sm border bg-stone-50 focus:outline-0 focus:shadow-md mb-4 border-stone-400 w-full px-2 py-1 rounded"
                }
                id="technician"
                name="technician"
              >
                <option className={"text-gray-400"} value={0}>
                  -- {t("newTicketTechnician0")}
                </option>
                <option value={2}>{t("newTicketTechnician2")}</option>
                <option value={56}>{t("newTicketTechnician56")}</option>
                <option value={17}>{t("newTicketTechnician17")}</option>
                <option value={18}>{t("newTicketTechnician18")}</option>
                <option value={19}>{t("newTicketTechnician19")}</option>
              </select>
            </label>
            <label className={"w-full"} htmlFor="description">
              {t("newTicketDescription")}
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={
                  "π.χ. Δεν μπορώ να εκτυπώσω στον εκτυπωτή του γραφείου"
                }
                className={
                  "text-sm border resize-none bg-stone-50 focus:outline-0 focus:shadow-md mb-4 border-stone-400 w-full px-2 py-1 rounded"
                }
                id="description"
                name="description"
                rows="10"
              ></textarea>
            </label>
            <div className="border-t border-stone-200 py-4 flex flex-row items-center justify-center w-full">
              <input
                className={
                  "px-3 py-2 rounded cursor-pointer bg-gray-800 text-white hover:bg-teal-800"
                }
                type="submit"
                value={t("newTicketSubmitButton")}
              />
            </div>
          </form>
        </div>
        {state.notification.visible && (
          <Notification
            isModal={true}
            closeNewTaskForced={closeNewTaskForced}
          />
        )}
      </div>
    </>
  );
}
