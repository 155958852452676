import React from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../Store";

function Footer() {
  const { t } = useTranslation();
  const [state, setState] = React.useContext(AppContext);

  return (
    <footer
      className={
        "bg-stone-50 fixed bottom-0 right-0 w-full py-4 px-8 md:px-4 border-t border-stone-200 flex flex-row items-center justify-end"
      }
    >
      <p className={"flex flex-row items-center"}>
        {t("totalValueOfService")}:
        <span className={"ml-2 font-bold"}>
          {state.tickets ? state.tickets.totalBilled : "--"}
        </span>
        <span className={"ml-1"}>€</span>
        <span className={"ml-2 text-sm"}>({t("excludingVat")})</span>
      </p>
    </footer>
  );
}

export default React.memo(Footer);
