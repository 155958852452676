import React from "react";

const initState = {
  language: "el",
  auth: null,
  user: null,
  company: "all",
  use: 2022,
  tickets: null,
  initTickets: null,
  loading: false,
  notification: {
    type: null,
    error: null,
    visible: false,
  },
  isOpen: false,
  newTaskIsOpen: false,
};

export const AppContext = React.createContext(initState);

function Store({ children }) {
  const [state, setState] = React.useState(initState);

  return (
    <AppContext.Provider value={[state, setState]}>
      {children}
    </AppContext.Provider>
  );
}

export default Store;
